<template>
  <div>
    <!-- picC2A971F5-2AAB-4BC9-8ABF-381C10EAD177.jpg -->
    <img class="rounded leftimage" style="width: 200px; height: 150px;" src="">

    <p>
      At this very moment the LEGO Universe is being built up from electronic bits and bytes. This super specialized setup work is being done by people called "programmers" or "developers." They work to create LEGO Universe, the first massive multiplayer online game for LEGO fans and supported by community members from around the world. They are super dedicated and they love to build with LEGO!
    </p>
    <p>
      Listen in while we gab about the game with the technical director, Erik.
    </p>
    <p>
      This month you also get a bonus interview with a lucky guy named Reed. He’s on the Kids’'Development Panel and he's actually played early versions of LEGO Universe!
    </p>
    <p>
      And now, heeeeere's Erik!
    </p>
    <p>
      <b>Tell us about yourself! </b>
    </p>
    <p>
      I love solving all sorts of puzzles: crosswords, acrostics, sudoku, kokuru, jigsaw, whatever! I also do magic and play music on the piano and the recorder. <br>My wife, Nan, is an English professor and we have two kids: Sam, who's thirteen, and Jacqueline – we call her "Q"-- age nine.
    </p>
    <p>
      <b>Are you a gamer?</b>
    </p>
    <p>
      Absolutely. I started playing on some of the first coin-operated arcade games, like Space Invaders and the original Donkey Kong. Now, we have practically every type of gaming console in our home!
    </p>
    <p>
      <b>Do you play with LEGO?</b>
    </p>
    <p>
      Yes, my kids and I play with LEGO all the time. Sometimes I get to bring home a new set from work; I’m really popular on those nights!
    </p>
    <p>
      <b>What's your favorite LEGO set? </b>
    </p>
    <p>
      I love the Café Corner. My kids and I spent hours putting that one together. I also like the Aqua Raiders ones with anglerfish.
    </p>
    <p>
      <b>What's the coolest part of working in the LU development team?</b>
    </p>
    <p>
      Everything about building games. I get to bring an entire imaginary world to life by combining art, sound, design, and programming. It's like writing a book and having it become real… it’s like magic.
    </p>
    <p>
      <b>Do you like the people you work with? </b>
    </p>
    <p>
      They're amazing! There’s just tremendous energy here; This is the most creative and funny group of people I’ve ever worked with. Most of us get around on scooters, but a few of us have miniature electric motorcycles. We’ve even been known to do a bit of after hours motorcycle-powered chair jousting!
    </p>
    <!-- pic6EF346AF-E105-40F5-863E-A7388A9E9332.jpg -->
    <img class="rightimage rounded" style="width: 200px; height: 250px;" src="">
    <p>
      <b>What's your workday like?</b>
    </p>
    <p>
      I spend a lot of time coordinating with people to make sure everything's running smoothly. I also help with the software design. I've done tons of software programming, so I try to help newer people avoid mistakes that I've already made.
    </p>
    <p>
      <b>What’s it like being a programmer?</b>
    </p>
    <p>
      It's like a being a chef who creates a recipe. The problem with computers is that they aren't very bright, so you need to tell them every single thing you want them to do. If you want a computer to put two eggs in the recipe, you also have to tell it to break the eggs and leave out the shells!
    </p>
    <p>
      <b>What’s a "game engine"?</b>
    </p>
    <p>
      It's software that handles repetitive details like shading, changing the point of view and hiding surfaces that shouldn't be visible. Using an existing game engine allows developers to focus on the most interesting parts of their particular game.
    </p>
    <p>
      <b>Will LU run on almost any computer?</b>
    </p>
    <p>
      The hardest part is making the game work for older, less sophisticated graphics cards, but we’re also making allowances for machines with things like less memory and slower processors.
    </p>
    <p>
      <b>What's been exciting about writing code for LU?</b>
    </p>
    <p>
      Coding anything is like solving a puzzle, which I love. What’s been especially thrilling about coding LEGO Universe is seeing the work appear on screen as living creatures, beautiful scenes and fantastic mini-games!
    </p>
    <p>
      <b>Has anything funny happened with the LU code?</b>
    </p>
    <p>
      Yes. We’ve had some really strange bugs, like tiny minifigs ending up with enormous hands, birds flying straight up and trees floating in the air! Sometimes the bugs are kind of cool and I’m tempted to keep them in the game ;-)
    </p>
    <p>
      <b>What will your minifig look like? </b>
    </p>
    <p>
      I haven't decided yet. As time passes I seem to have less and less of my own hair, so maybe I'll give my minifig a beard and nice long hair. Maybe spiky hair! Thinking… thinking….
    </p>
    <div style="clear:both;"></div>
  </div>
</template>
